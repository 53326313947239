import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from '@farewill/ui'

import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'
import { getQueryParameters } from 'lib/url/getQueryParameters'
import {
  ROOT_URL,
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
} from 'config'

import { getDynamicContentForArticle } from '../helpers'
import LocalFuneralPageContent from '../../../general/templates/LocalFuneralPage/LocalFuneralPageContent'
import { parseStructuredData } from '../../../general/templates/helpers'
import Loader from '../../components/Loader'
import PreviewModeSwitch from '../../components/PreviewModeSwitch'

let requestCache

const LocalFuneralPage = ({ data: { allCrematorium } }) => {
  const [funeralPage, setFuneralPage] = useState()
  const [parsedPhoneNumber, setParsedPhoneNumber] = useState()
  const [isPreviewMode, setIsPreviewMode] = useState(true)
  const [hasLiveMode, setHasLiveMode] = useState(false)
  const { slug } = getQueryParameters()

  useEffect(() => {
    if (!requestCache) {
      getDynamicContentForArticle('funerals', { slug }).then((res) => {
        requestCache = res
        setFuneralPage(isPreviewMode ? res.preview : res.live)
        setHasLiveMode(Object.entries(res.live).length > 0)
      })
    } else {
      setFuneralPage(isPreviewMode ? requestCache.preview : requestCache.live)
    }
  }, [isPreviewMode])

  useEffect(() => {
    const { phoneNumber } = parseStructuredData(funeralPage?.data)

    setParsedPhoneNumber(phoneNumber)
  }, [funeralPage])

  return (
    <>
      <Wrapper
        style={{
          position: 'relative',
          overflowX: 'hidden',
        }}
      >
        <JSONLD
          data={[
            {
              '@context': 'https://schema.org/',
              '@type': 'Product',
              name: 'Farewill Funerals',
              brand: 'Farewill',
              image: `${ROOT_URL}/logos/share.jpg`,
              description:
                "From a simple service at your local crematorium, to a family gathering by the sea, we'll help you create a beautiful send-off at an affordable price.",
              aggregateRating: {
                '@type': 'AggregateRating',
                bestRating: '5',
                ratingCount: TRUSTPILOT_REVIEWS_TOTAL,
                ratingValue: TRUSTPILOT_RATING_OUT_OF_5,
                worstRating: '1',
              },
            },
          ]}
        />
        <DefaultLayout
          title={
            funeralPage?.metaTitle || funeralPage?.title || 'Article Preview'
          }
          description={
            (funeralPage?.metaDescription &&
              funeralPage?.metaDescription.metaDescription) ||
            (funeralPage?.description && funeralPage?.description.description)
          }
          telephoneNumber={parsedPhoneNumber}
          usePhoneNumberTracking={false}
        >
          {funeralPage ? (
            <>
              <PreviewModeSwitch
                isPreviewMode={isPreviewMode}
                hasLiveMode={hasLiveMode}
                onChange={() =>
                  setIsPreviewMode((prevState) =>
                    hasLiveMode ? !prevState : true
                  )
                }
              />
              <LocalFuneralPageContent
                data={funeralPage}
                allCrematorium={allCrematorium}
              />
            </>
          ) : (
            <Wrapper centered>
              <Loader />
            </Wrapper>
          )}
        </DefaultLayout>
      </Wrapper>
    </>
  )
}

LocalFuneralPage.propTypes = {
  data: PropTypes.shape({
    allCrematorium: PropTypes.arrayOf(
      PropTypes.shape({
        edges: PropTypes.shape({
          node: PropTypes.shape({
            attributes: PropTypes.shape({
              externalId: PropTypes.string.isRequired,
              latitude: PropTypes.number.isRequired,
              longitude: PropTypes.number.isRequired,
              address: PropTypes.string.isRequired,
              description: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
            }),
          }),
        }),
      })
    ).isRequired,
  }).isRequired,
}

export const query = graphql`
  query {
    allCrematorium {
      edges {
        node {
          ...SingleCrematorium
        }
      }
    }
  }
`

export default LocalFuneralPage
